/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from 'react';
import StockChart from "../../components/chartgraphique/StockChart";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getDatachartjb1m,
  getDataForCurrentAndLast10Minutes, postVente,
} from '../../Api/authApi.js';
import { useLocation } from 'react-router-dom';
import Loader from "../../components/Loader";

const PorteFeuilleDetail = () => {
  const location = useLocation();
  const segments = location.pathname.split('/'); // Divise l'URL en segments
  const marchesid = segments[2]; // Récupère la 3ème partie de l'URL (après `portefeuille_detail`)
  const leagueid = segments[3]; // Récupère la 4ème partie de l'URL

  // console.log("marchesid:", marchesid, "leagueid:", leagueid); 
   const [selectedMarche, setSelectedMarche] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const userId = useSelector((state) => state.auth.user.id); // Récupération de l'utilisateur connecté
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarketDetails = async () => {
      try {
        setLoading(true);

        // console.log("Paramètres reçus via URL :", { marchesid, leagueid });

        if (!marchesid || !leagueid) {
          setError("Paramètres manquants dans l'URL.");
          console.error("Paramètres d'URL invalides.");
          return;
        }

        // Récupérer tous les marchés pour l'utilisateur
        const data = await getMarches(userId);
        // console.log("Marchés récupérés :", data);

        // Recherche du marché correspondant aux paramètres
        const market = data.find(
          (m) => m.marche_id === parseInt(marchesid) && m.league_id === parseInt(leagueid)
        );

        if (!market) {
          setError("Marché introuvable avec les paramètres fournis.");
          console.error("Aucun marché correspondant trouvé.");
          return;
        }

        // console.log("Marché sélectionné :", market);

        // Récupération des données enrichies pour le marché sélectionné
        const enrichedData = await getDataForCurrentMinutes(marchesid, leagueid);
        // console.log("Données enrichies :", enrichedData);

        setSelectedMarche({
          ...market,
          current_value: enrichedData.value,
          last_value: enrichedData.last_value,
          difference: enrichedData.difference,
          heure_complete: enrichedData.hour,
        });
      } catch (err) {
        setError(err.message || "Une erreur s'est produite.");
        console.error("Erreur lors de la récupération des marchés :", err);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchMarketDetails();
    }
  }, [userId, marchesid, leagueid]);

  const handleAchat = async () => {
    if (!selectedMarche) return;
    
    const { marche_id, titre, icon, type, league_id, current_value, heure_complete, difference } = selectedMarche;
  
    try {
      const value = parseFloat(current_value).toFixed(2);
      await postAchat(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_achat', {
        state: { marche_id, titre, icon, value, heure_complete, difference, type, league_id },

      });

    } catch (error) {
      console.error("Erreur lors de l'achat :", error);
    }
  };

  const handleVente = async () => {
    if (!selectedMarche) return;
    const { marche_id, titre, icon, type, league_id, current_value, heure_complete, difference } = selectedMarche;

    try {
      const value = parseFloat(current_value).toFixed(2);
      await postVente(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_vente', {
        state: { marche_id, titre, icon, value, heure_complete, difference, type, league_id },
      });
    } catch (error) {
      console.error("Erreur lors de la vente :", error);
    }
  };

  if (loading) return <div>   
         <Loader size={560} /> 
</div>;
  // if (error) return <div>{error}</div>;

  return (
    <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] py-7 pb-12">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col gap-4 sm:px-10 md:px-16">
        <div className="lg:w-[98%] w-[100%] lg:border border-gray-400 lg:rounded-lg md:border xl:border 2xl:border shadow bg-[#252f3d] text-white flex justify-center mx-auto py-1 lg:py-4 flex-col border-b">
          {selectedMarche && (
            <div className="flex justify-between gap-1 items-center py-4">
              <div className="flex gap-2 flex-row justify-center text-center items-center">
                <img
                  src={`https://tradingseries.fr/admin/storage/app/public/${selectedMarche.icon}`}
                  className="w-9 bg-transparent rounded-full"
                  alt={selectedMarche.titre}
                />
                <p className="text-[14px] font-bold">{selectedMarche.titre}</p>
              </div>
            </div>
          )}
          {/* Passez les paramètres directement à StockChart */}
          <StockChart marcheId={marchesid} leagueid={leagueid} />

          <div className="flex justify-center items-center">
            {selectedMarche && (
              <div className="flex justify-between gap-1 items-center py-4">
                <div className="flex gap-2">
                  <button
                    className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md"
                    onClick={handleAchat}
                  >
                    ACHAT
                  </button>
                  <button
                    className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md"
                    onClick={handleVente}
                  >
                    VENTE
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedMarche && (
          <div className="lg:w-[98%] w-[98%] p-2 sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-[#252f3d] text-white flex py-2 lg:pt-20 mx-auto flex-col">
            <div className="flex flex-col gap-3 mx-3">
              <p className="text-lg font-medium text-justify">Profil</p>
              <p className="text-[13px] lg:text-lg font-medium text-justify">{selectedMarche.description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PorteFeuilleDetail;
