import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "../redux/store";
import PrivateRoute from "./PrivateRoute";

import {
    Accueil,
    AuthPage,
    LoginPage,
    SignUpPage,
    ForgetPasswordPage,
    EditLeague,
    RecapLeague,
    LeagueTeams,
    CreatedLeague,
    PorteFeuille,
    PortefeuilleMenu,
    Classement,
    Profil,
    UpdateUser,
    Updateequipe,
    UpdatePassword,
    UpdatePassword2,
    PorteFeuilleDetail,
    PorteFeuilleAcaht,
    PorteFeuilleVente,
    PorteFeuilleActions,
    PorteFeuilleBitcoin,
    PorteFeuilleMatierePremiere,
    PorteFeuilleDevises,
    PorteFeuilleHistorique,
    Layout,
    PasswordReset,
    VideoNotification,
    FavorisPage,
    Loader


} from "../pages";
import datablog from "../components/datablog";
import ArticleDetail from "../pages/ArticleDetail";
const MainNavigator = () => {
    return (
        <Routes>
           
            <Route path="/Spinner" element={<Loader />} />
            <Route path="/" element={<AuthPage />} />
            <Route path="/connexion" element={<LoginPage />} />
            <Route path="/VideoNotification" element={<VideoNotification />} />
            <Route path="/inscription" element={<SignUpPage />} />
            <Route path="/mot-de-passe-oublie" element={<ForgetPasswordPage />} />
            <Route path="/reinitialiser-mot-de-passe" element={<PasswordReset />} />

            <Route path="/" element={<Layout />} >
                <Route path="accueil" element={<PrivateRoute element={<Accueil />} />} />
                <Route path="portefeuille" element={<PrivateRoute element={<PorteFeuille />} />} />
                <Route path="portefeuille_menu/:marcheid?/:leagueid?" element={<PrivateRoute element={<PortefeuilleMenu />} />} />
                {/* <Route path="portefeuille_menu" element={<PrivateRoute element={<PortefeuilleMenu />} />} /> */}
                {/* <Route path="portefeuille_detail" element={<PrivateRoute element={<PorteFeuilleDetail />} />} /> */}
                <Route path="/portefeuille_detail/:marcheid/:leagueid"  element={<PrivateRoute element={<PorteFeuilleDetail />} />} />

                <Route path="portefeuille_achat" element={<PrivateRoute element={<PorteFeuilleAcaht />} />} />
                {/* <Route path="/portefeuille_achat/:marcheId" element={<PrivateRoute element={<PorteFeuilleAcaht />} />} /> */}
                <Route path="portefeuille_vente" element={<PrivateRoute element={<PorteFeuilleVente />} />} />
                <Route path="portefeuille_actions/:type" element={<PrivateRoute element={<PorteFeuilleActions />} />} />
                <Route path="Favorites_marchés" element={<PrivateRoute element={<FavorisPage />} />} />
                <Route path="portefeuille_crypto" element={<PrivateRoute element={<PorteFeuilleBitcoin />} />} />
                <Route path="portefeuille_devises" element={<PrivateRoute element={<PorteFeuilleDevises />} />} />
                <Route path="portefeuille_matiere_premiere" element={<PrivateRoute element={<PorteFeuilleMatierePremiere />} />} />
                <Route path="portefeuille_historique" element={<PrivateRoute element={<PorteFeuilleHistorique />} />} />
                <Route path="classement" element={<PrivateRoute element={<Classement />} />} />
                <Route path="profil" element={<PrivateRoute element={<Profil />} />} />
                <Route path="modifie-nom-utilisateur" element={<PrivateRoute element={<UpdateUser />} />} />
                <Route path="modifie-nom-equipe" element={<PrivateRoute element={<Updateequipe />} />} />
                <Route path="modifie-mot-depasse" element={<PrivateRoute element={<UpdatePassword />} />} />
                <Route path="modifie-mot-de-passe" element={<PrivateRoute element={<UpdatePassword2 />} />} />
                <Route path="/article/:id" element={<PrivateRoute element={<ArticleDetail articles={datablog} />} />} />
            </Route>


            <Route path="/ligue/*" element={<AdminRoutes />} />
            <Route path="*" element={<h1>La route n'existe pas</h1>} />
        </Routes>
    )
}
function AdminRoutes() {
    return (
        <Routes>
            <Route path="/" element={<CreatedLeague />} />
            <Route path="create-ligue" element={<CreatedLeague />} />
            <Route path="recap_ligue" element={<RecapLeague />} />
            <Route path="modifer_ligue" element={<EditLeague />} />
            <Route path="create_equipe" element={<LeagueTeams />} />
            <Route path="*" element={<h1>La sous-route n'existe pas</h1>} />
        </Routes>
    );
}

export default MainNavigator