import React, { useState, useEffect } from "react";
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsername } from '../../Api/authApi.js';
import { updateUser } from '../../redux/authSlice'; // Importez l'action 
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const UpdateUser = () => {
    const [username, setUsername] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const handleUpdate = async () => {
        if (!username.trim()) {
            alert("Le nom d'utilisateur est requis.");
            return;
        }

        try {
            const id = user.id; // Assurez-vous que l'ID utilisateur est disponible
            const data = await updateUsername(id, username);
            toast.success(data.message);
            navigate('/profil');

            dispatch(updateUser({ username })); // Mettez à jour le nom d'utilisateur dans Redux

            // alert('Nom d\'utilisateur mis à jour avec succès');
        } catch (error) {
            toast.warning(error.message);

        }
    };
    return (
        <section className=' bg-[#252f3d] text-white relative'>
            <Link className='absolute left ' to='/profil'>
                <img className={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
            </Link>
            <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">

                <div className='flex items-center flex-col justify-center '>
                    <div className='flex  justify-center h-[220px] flex-col items-center'>
                        <CustomHeader width="w-[50%] mt-9" />
                    </div>
                    <div className='flex items-center justify-center flex-col space-y-7  w-[80%] sm:w-[60%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
                        <div className=' flex-col justify-center gap-4 flex items-center  '>
                            <p className=' font-medium  text-center text-sm py-4 '>Modifie ton nom d'utilisateur dès maintenant !</p>

                            <div className="relative w-[83%]  lg:w-full items-center flex justify-center ">
                                <input
                                    type="text"
                                    name="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className={`pl-12 pr-4 py-2 border rounded-sm text-black  text-[12px]  md:text-[14px]  sm:text-[14px]  lg:text-[14px]  xl:text-[14px] 2xl:text-[14px]   w-[100%] placeholder-black font-medium `}
                                    placeholder="Nouveau nom d&rsquo;utilisateur" />
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center  pointer-events-none">
                                    <svg className="w-7 h-7 text-black " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <div to='/profil' className='w-full  items-center flex justify-center '>
                            <button className={` hover:bg-gray-400  border rounded-sm text-black  w-[83%] lg:w-full  py-1 flex pl-3  gap-6  bg-gray-300 font-medium text-[13px] text-center items-center  `} onClick={handleUpdate}
                            >
                                <span><svg className="w-6 h-6 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                                </svg></span>
                            <span className='text-sm'>Confirmer</span>
                        </button>
                    </div>
                </div>

            </div>
            <div className='flex items-center flex-col space-y-5 justify-center '>
                <CustomFooter styelefooter='text-justify mx-9 text-[14.5px]' />
            </div>

        </div>

            </div >
        </section >

    )
}

export default UpdateUser