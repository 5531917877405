/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from "react-flags-select";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProgressiveLineChart from "../../components/chartgraphique/progressiveLineChart";
import { getMarches, getDataForCurrentMinutes, postAchat, postVente, getImagesByMarketType } from '../../Api/authApi.js';
import Carousel from "../../components/Carousel";
import { FaStar } from "react-icons/fa"; // Importer l'icône étoile pleine
import Loader from "../../components/Loader";

const PortefeuilleMenu = () => {
  const location = useLocation();
  const segments = location.pathname.split("/"); // Divise l'URL en segments

  const [marchesid, setMarchesid] = useState(null);
  const [leagueid, setLeagueid] = useState(null);
  
  const [selectedTime, setSelectedTime] = useState('6h');
  const timeOptions = [
    { key: '6h', label: '6h' },
    { key: '1j', label: '1j' },
    { key: '1w', label: '1w' },
    { key: '1m', label: '1m' },
    { key: 'max', label: 'max' }

  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user.id);

  const [selectedMarche, setSelectedMarche] = useState(null);
  const [marches, setMarches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [marketImages, setMarketImages] = useState({});

  // Récupérer les favoris depuis Redux
  const favoriteMarkets = useSelector((state) => state.market.favoriteMarkets);
  const isFavorite = favoriteMarkets.some(fav => fav.marche_id === selectedMarche?.marche_id);



  useEffect(() => {
    const fetchMarches = async () => {
      try {
        setLoading(true);
  
        // Récupérer tous les marchés pour l'utilisateur
        const data = await getMarches(userId);
  
        if (data.length === 0) {
          setError("Aucun marché disponible.");
          return;
        }
  
        // Extraire les paramètres de l'URL
        const segments = location.pathname.split("/"); // Divise l'URL en segments
        let urlMarchesid = segments[2]; // Récupère la 3ème partie de l'URL
        let urlLeagueid = segments[3]; // Récupère la 4ème partie de l'URL
  
        // Si les paramètres URL sont manquants, utiliser le premier marché
        if (!urlMarchesid || !urlLeagueid) {
          urlMarchesid = data[0].marche_id.toString();
          urlLeagueid = data[0].league_id.toString();
        }
  
        // Mettre à jour les states
        setMarchesid(urlMarchesid);
        setLeagueid(urlLeagueid);
  
        // Recherche du marché correspondant
        const market = data.find(
          (m) => m.marche_id === parseInt(urlMarchesid) && m.league_id === parseInt(urlLeagueid)
        );
  
        if (!market) {
          setError("Marché introuvable avec les paramètres fournis.");
          return;
        }
  
        // Récupération des données enrichies pour le marché sélectionné
        const enrichedData = await getDataForCurrentMinutes(urlMarchesid, urlLeagueid);
  // console.log('enrichedData',enrichedData)
        setSelectedMarche({
          ...market,
          current_value: enrichedData.value,
          last_value: enrichedData.last_value,
          difference: enrichedData.difference,
           heure_complete: enrichedData.hour,
});
      } catch (err) {
        setError(err.message || "Une erreur s'est produite.");
        // console.error("Erreur lors de la récupération des marchés :", err);
      } finally {
        setLoading(false);
      }
    };
  
    if (userId) {
      fetchMarches();
    }
  }, [userId, location.state]);
  useEffect(() => {
    const fetchMarketType = async () => {
      try {
        const response = await getImagesByMarketType();
        setMarketImages(response);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketType();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedTime(event.target.value);
    // setLoading(true); // Activer le Loader lorsque la sélection change

  };
  const handleDataLoaded = () => {
    setLoading(false);
  };


  const handleAchat = async () => {
    const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = selectedMarche;
    let value = parseFloat(current_value).toFixed(2);

    try {
      await postAchat(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_achat', {
        state: { marche_id, titre, icon, value, heure_complete, difference, type, league_id }
      });
    } catch (error) {
      // console.error('Erreur lors de l\'achat:', error);
    }
  };

  const handleVente = async () => {
    const { marche_id, titre, icon, type, league_id, current_value, difference, heure_complete } = selectedMarche;
    let value = parseFloat(current_value).toFixed(2);

    try {
      await postVente(userId, marche_id, value, type, league_id);
      navigate('/portefeuille_vente', {
        state: { marche_id, titre, icon, value, heure_complete, difference, type, league_id }
      });
    } catch (error) {
      console.error('Erreur lors de la vente:', error);
    }
  };

  return (
    <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-14 pt-6">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col gap-4 sm:px-10 md:px-16">
        <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-500 lg:rounded-lg sm:border md:border xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto py-1 lg:py-4 flex-col border-b">
          <div className="flex justify-between pt-2">
            <p className="flex justify-start text-left font-sans font-bold px-2 text-md" style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Marchés financiers</p>
            <Link to={`/Favorites_marchés`} className='p-2'>
              <button className=" bg-[#252f3d] text-white flex text-xs  border-gray-500 border gap-x-4 pr-6 pl-4 items-center ">
                <span className="">
                  <svg className="w-4 h-4 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                  </svg>

                </span>
                <span className=" font-medium text-xs lg:text-sm">Favoris</span>
                {/* <span className=" font-medium text-xs lg:text-sm">{marchesid}</span> */}              </button></Link>
          </div>

          {/* {selectedMarche && (
            <div className="flex justify-between gap-1 items-center py-4">
              <div className="flex gap-2 flex-row justify-center text-center items-center">
                <div className="relative">

                  {isFavorite && (
                    <FaStar className="text-yellow-500 absolute -top-1 left-0" fontSize={22} />
                  )}
                  <img
                    src={`https://tradingseries.fr/admin/storage/app/public${selectedMarche.icon}`}
                    className="w-14 h-14 bg-transparent rounded-full"
                    alt={selectedMarche.titre}
                  />
                </div>
                <div>
                  <p className="text-[12px] font-semibold text-justify">{selectedMarche.titre}</p>
                  <p className="text-[11px] text-gray-400 font-medium">{selectedMarche.type}</p>
                </div>
              </div>
              <div className="flex gap-2">
                <button className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md" onClick={handleAchat}>
                  ACHAT
                </button>
                <button className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md" onClick={handleVente}>
                  VENTE
                </button>
              </div>
            </div>
          )} */}
          {selectedMarche ? (
            <div className="flex justify-between gap-1 items-center py-4">
              <div className="flex gap-2 flex-row justify-center text-center items-center">
                <div className="relative">
                  {isFavorite && (
                    <FaStar className="text-yellow-500 absolute -top-1 left-0" fontSize={22} />
                  )}
                  <img
                    src={`https://tradingseries.fr/admin/storage/app/public/${selectedMarche.icon}`}
                    className="w-14 h-14 bg-transparent rounded-full"
                    alt={selectedMarche.titre}
                  />
                </div>
                <div>
                  <p className="text-[14px] font-semibold text-justify">{selectedMarche.titre}</p>
                  {/* <p className="text-[11px] text-gray-400 font-medium">{selectedMarche.type}</p> */}
                </div>
              </div>
              <div className="flex gap-2">
                <button className="py-1 px-3 text-[12px] bg-[#82bf3e] text-black font-sans font-bold rounded-md" onClick={handleAchat}>
                  ACHAT
                </button>
                <button className="py-1 px-3 text-[12px] bg-[#97271d] text-black font-sans font-bold rounded-md" onClick={handleVente}>
                  VENTE
                </button>
              </div>
            </div>
          ) : (
            <p className="text-center text-gray-500 font-medium py-4">
              {/* Veuillez sélectionner un marché. */}
            </p>
          )}


          <div className="w-[100%]">
            {leagueid && marchesid ? (
              <ProgressiveLineChart
                selectedTime={selectedTime}
                leagueid={leagueid}
                marcheId={marchesid}
                loading={loading}
                onDataLoaded={handleDataLoaded}
              />
            ) : (
              <p className="text-center text-gray-500 font-medium py-4">
                Veuillez sélectionner un marché.
              </p>
            )}
          </div>

          <div className="flex justify-between px-3 gap-1 items-center lg:py-4">
            <div className="w-auto">
              {leagueid && marchesid ? (
                <select
                  id="time-select"
                  value={selectedTime}
                  onChange={handleSelectChange}
                  className="mt-1 block w-full pl-3 py-2 border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm text-xs rounded-md text-white bg-[#252f3d]"
                >
                  {timeOptions.map((time, index) => (
                    <option key={index} value={time.key}>
                      {time.label}
                    </option>
                  ))}
                </select>) : (
                <p className="text-center text-gray-500 font-medium py-4">
                  {/* Veuillez sélectionner un marché. */}
                </p>
              )}

            </div>
            {selectedMarche ? (<Link to={`/portefeuille_detail/${marchesid}/${leagueid}`} className="text-[11px] font-medium lg:text-sm ">
              <span className="slot-bYDQcOkp">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                  <path fill="currentColor" d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"></path>
                </svg>
              </span>
            </Link>) : ''}
          </div>
        </div>
        <div className="lg:w-[98%] w-[98%] px-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex justify-center mx-auto lg:py-4   flex-col  ">


          <div className="lg:w-[98%] w-[98%] bg-[#252f3d] text-white flex justify-center mx-auto lg:py-4 flex-col">
            <div className="w-auto flex justify-between py-4">
              <Link to="/portefeuille_actions" className="flex items-center text-[13px] font-medium">

              </Link>
              {/* <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-3 h-3 text-gray-300 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block  w-40 ps-10 text-sm text-gray-300 border border-gray-400 rounded-xs bg-[#252f3d] "
                  placeholder="Recherche"
                  required=""
                />

              </div> */}

            </div>
            {Object.entries(marketImages).map(([marketType, images]) => (
              <div key={marketType} className="">
                <div className="w-auto flex items-center py-1 mt-2">
                  <span>
                    <svg className="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7" />
                    </svg>
                  </span>

                  {/* Utilisez Link pour passer le type via l'URL */}
                  <Link
                    to={`/portefeuille_actions/${marketType}`}
                    className="cursor-pointer text-sm font-semibold"
                    style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}
                  >
                    {marketType}
                  </Link>
                </div>

                <Link to={`/portefeuille_actions/${marketType}`} className="cursor-pointer text-sm font-semibold">
                  <Carousel images={images.map(image => `https://tradingseries.fr/admin/storage/app/public/${image.image_path}`)} />
                </Link>
              </div>
            ))}

          </div>

        </div>
      </div>

    </div >


  );
};

export default PortefeuilleMenu;
