/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from 'react-redux';

import bitcoin from '../../images/bitcoin.png'
import USD from '../../images/USD.png'
import totalenergies from '../../images/totalenergie.png'
import Ethereum from '../../images/Ethereumimg.png';
import Drawer from "../../components/Drawer";
import { Link } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import ProgressiveLineChart from "../../components/ProgressiveLineChartv2";
import { getAchatsEtVentes, getAllPendingTransactions, getAllTransactions, getPortefeuilleData, getAllTransactionsByStatus } from '../../Api/authApi.js';

const PorteFeuilleHistorique = () => {
    const [selectedTime, setSelectedTime] = useState('');



    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [portefeuille, setPortefeuille] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);

    const user = useSelector((state) => state.auth.user);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        const fetchPendingTransactions = async () => {
            try {
                const data = await getAllTransactionsByStatus(user.id);
                setTransactions(data.transactions); // Mettre à jour les transactions avec les achats et ventes en attente
                // console.log('getAllTransactionsByStatus',data)

                // console.log(data); // Log the data to verify the response
            } catch (error) {
                setError(error.message);
            }
        }

        fetchPendingTransactions();
        // hadi 
        const intervalId = setInterval(() => {
            fetchPendingTransactions();
        }, 300000);

        return () => clearInterval(intervalId);
    }, [user]);
    useEffect(() => {
        const fetchPortefeuilleData = async () => {
            if (user) {
                try {
                    const data = await getPortefeuilleData(user.id);
                    setPortefeuille(data);
                    // console.log('getPortefeuilleData', data)

                } catch (error) {
                    setError(error.message);
                }
            }
        };

        fetchPortefeuilleData();
    }, [user]);
    function formatMontant(montant) {
        return parseFloat(montant) // Convertit la chaîne en nombre
            .toFixed(2) // Assure qu'il y a toujours 2 décimales
            .replace(/\d(?=(\d{3})+\.)/g, '$& '); // Ajoute un espace pour les milliers
    }


    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:py-2 pt-12">

            <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
                <div className="lg:w-[98%] w-[98%] px-2 pt-2 lg:border border-gray-400 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto   lg:pt-4  flex-col  ">


                    <div className="flex flex-col gap-5">
                        <p className=" font-bold text-[15px] px-2  lg:text-lg ">Mon Historique</p>
                        <div className="flex justify-center gap-3 lg:justify-center lg:gap-4 items-center p-3">
                            <div className="flex flex-col gap-1 justify-center">
                                <p className="lg:text-lg text-[12px] font-medium">Capital initial</p>
                                {/* <p className="lg:text-lg text-[12px] font-medium text-center">
                                    {portefeuille ? portefeuille.montant_initial + ' $' : 'Loading...'}
                                </p> */}
                                <p className="lg:text-lg text-[12px] font-medium text-center">
                                    {portefeuille && portefeuille.montant_initial !== undefined
                                        ? formatMontant(portefeuille.montant_initial) + ' $'
                                        : '...'}
                                </p>

                            </div>
                            {/* <div className="flex flex-col justify-center items-center">
                                <p>
                                    <span>
                                        <svg className="lg:w-8 lg:h-8 h-6 w-6 font-extrabold text-[#678f2c]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 15 7-7 7 7" />
                                        </svg>
                                    </span>
                                </p>
                                <p className="text-[#678f2c] lg:text-lg text-[12px] font-medium">
                                    {portefeuille ? `${parseFloat(portefeuille.difference_percentage).toFixed(2)}%` : 'Loading...'}
                                </p>
                            </div> */}


                            <div className="flex flex-col justify-center items-center">
                                <p>
                                    <span>
                                        <svg className={`lg:w-8 lg:h-8 h-6 w-6 font-extrabold ${portefeuille && portefeuille.difference_percentage < 0 ? 'text-red-500' : 'text-[#678f2c]'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 15 7-7 7 7" />
                                        </svg>
                                    </span>
                                </p>
                                <p className={`lg:text-lg text-[12px] font-medium ${portefeuille && portefeuille.difference_percentage < 0 ? 'text-red-500' : 'text-[#678f2c]'}`}>
                                    {portefeuille ? `${!isNaN(parseFloat(portefeuille.difference_percentage)) ? parseFloat(portefeuille.difference_percentage).toFixed(2) : '0.00'}%` : 'Loading...'}
                                </p>
                            </div>

                            <div className="flex flex-col gap-1 justify-center">
                                <p className="lg:text-lg text-[12px] font-medium">Capital actuel</p>
                                {/* <p className="lg:text-lg text-[12px] font-medium text-center">
                                    {portefeuille ? portefeuille.montant_total + ' $' : 'Loading...'}
                                </p> */}
                                <p className="lg:text-lg text-[12px] font-medium text-center">
                                    {portefeuille && portefeuille.montant_total !== undefined
                                        ? formatMontant(portefeuille.montant_total) + ' $'
                                        : '...'}
                                </p>


                            </div>
                        </div>
                    </div>

                    <div className="pt-2">
                        <div className=" mb-1 flex pl-2  gap-6 justify-between sm:justify-between  lg:justify-between  md:justify-between  xl:justify-between  2xl:justify-between items-center w-[100%] overflow-auto ">
                            <span className="p-2 font-medium text-gray-900">
                            </span>
                            <span className=" font-medium text-[12px] lg:text-lg">Nombre</span>
                            <span className=" font-medium text-[12px] lg:text-lg">Valeur</span>
                            <span className=" font-medium text-[12px] lg:text-lg">G/P</span>
                            <span className=" font-medium text-[12px] lg:text-lg">Statut</span>



                        </div>
                        {transactions.length > 0 ? (
                            transactions.map((item, index) => (
                                <div key={index} className=" border-b border-gray-500  flex gap-2  justify-between items-center w-[100%] overflow-auto ">
                                    <span className="p-2 font-medium text-gray-900">
                                        <img src={`https://tradingseries.fr/admin/storage/app/public/${item.
                                            market_icon
                                            }`} className="lg:w-10 lg:h-10 w-6 h-6" alt="Logo" />
                                    </span>
                                    <span className=" font-semibold lg:text-[16px] text-[12px]">{item.quantity}</span>
                                    <span className=" font-semibold lg:text-[16px]  text-[12px]" >
                                        {item.montant}€
                                    </span>
                                    {/* <span className=" font-medium lg:text-[16px]  text-[12px]" style={{
                                    color: item.gain_loss > 0 ? 'green' : 'red'
                                }}>  {item.gain_loss > 0 ? `+${item.gain_loss}$` : `${item.gain_loss}$`}</span> */}
                                    {/* <span className="font-normal text-sm" style={{ color: item.gain_perte_color }}>{Number(item.gain_loss).toFixed(2)}</span> */}
                                    <span className="font-semibold text-sm" style={{ color: item.gain_perte_color }}>
                                        {item.gain_loss >= 0 ? `+${Number(item.gain_loss).toFixed(2)}` : `${Number(item.gain_loss).toFixed(2)}`}€
                                    </span>

                                    <span className="px-3 py-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                        </svg>
                                    </span>
                                </div>
                            ))
                        ) : (
                            <div className="text-center py-5">
                                <p className="text-gray-400 text-[14px] py-4 flex justify-center  items-center p-4 text-center" > Aucune transaction disponible. </p>

                            </div>
                        )}
                    </div>

                </div>
            </div>


        </div>

    )
};
export default PorteFeuilleHistorique;
