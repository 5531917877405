/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'
import CustomInput from '../../components/custominput'
import key from '../../images/key.png'
import unlock from '../../images/unlock.png'
import CustomButton from '../../components/custombutton'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const CreatedLeague = () => {
  return (
    <section className=' bg-[#252f3d] text-white relative'>
      {/* <Link to='/ligue' className='absolute left '>
        <img className{`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
      </Link> */}
      <div className='bg-[#252f3d] text-white w-[100vw]  h-screen flex justify-center'>

        <div className='flex items-center flex-col space-y-3 justify-center w-[100%] sm:w-[70%]  md:w-[80%]   lg:w-[70%]  xl:w-[70%]  2xl:w-[60%] '>
          <CustomHeader width="w-[60%]" />
          <div className='items-center flex-col space-y-7  w-[70%] sm:w-[70%]  md:w-[45%]   lg:w-[45]  xl:w-[45%]  2xl:w-[45%] '>
            <CustomInput
              styleinput=" flex text-center rounded-sm  font-bold text-[16px]  md:text-[16px] pl-0 sm:text-[16px]  lg:text-[16px]  xl:text-[16px] 2xl:text-[16px]  "
              placeholder='Nom de la ligue'
            />

            <Link to="create_equipe" className=' flex justify-center w-[100%]'>
              <CustomButton
                styleinput="flex bg-gray-300  text-black  justify-center text-center rounded-sm  font-bold  text-[15px]  md:text-[16px]  sm:text-[16px]  lg:text-[16px]  xl:text-[16px] 2xl:text-[16px] pl-0  "
                text='Créer une ligue'
              />
            </Link>
            <Link to="modifer_ligue" className=' flex justify-center w-[100%]'>
              <CustomButton
                styleinput="flex bg-[#252f3d]  text-white  justify-center text-center rounded-sm  font-bold text-[16px] pl-0  "
                text='Modifier une ligue'
              />
            </Link>



          </div>

          <div className='flex items-center flex-col space-y-3 justify-center'>
            <p className='mt-4 max-w-auto  mx-auto text-center md:text-[19px] sm:text-[19px] lg:text-[19px]  xl:text-[23px]  2xl:text-[23px] items-center text-[14px]  font-semibold font-IMFellDW '>"Trade avec plaisir,  gagne avec succès"</p>

          </div>

        </div>

      </div>
    </section>

  )
}

export default CreatedLeague