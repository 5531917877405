import React from 'react';

export const Loader = ({ size }) => {
  return (
    <div className="flex justify-center items-center  ">
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className="flex justify-center items-center">
      

            <div className="w-16 h-16 rounded-full animate-spin absolute
               border-8 border-dashed border-gray-400 border-t-transparent"></div>

      </div>
    </div>

  );
};

export default Loader;
