import React, { useState } from 'react';
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'
import CustomInput from '../../components/custominput'
import key from '../../images/key.png'
import send from '../../images/send.svg'
import CustomButton from '../../components/custombutton'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import { forgetPassword } from '../../Api/authApi.js';
import { toast } from 'react-toastify';

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Vérification de la validité de l'email
    if (!/\S+@\S+\.\S+/.test(email)) {
      toast.warning("Veuillez entrer une adresse e-mail valide.");
      return;
    }
    try {
      const response = await forgetPassword({ email });
      // console.log('Réponse du serveur :', response); // Vérifiez la réponse du serveur
      if (response) {
        toast.success(response.message);
        setMessage(response.data);
      } else {
        toast.warning('Réponse vide du serveur.');
      }
    } catch (error) {
      console.log('Erreur lors de la requête :', error); // Affichez l'erreur pour le débogage
      toast.warning(error.message);
    }
  };

  return (
    <section className=' bg-[#252f3d] text-white relative'>
      <Link to='/connexion' className='absolute left '>
        <img className={`w-16 h-16 pl-8 pt-9`} src={next} alt="logotrading" />
      </Link>
      <div className='bg-[#252f3d] flex-col  text-white w-[100%]  h-[100vh] flex '>
        <div className='flex items-center flex-col space-y-1 pt-12 '>
          <div className='flex  justify-center h-[230px] flex-col items-center'>
            <CustomHeader width="" />
          </div>
          <h1 className='flex justify-center text-center  font-bold font-sans text-[30px] pb-4 ' style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Trading Series</h1>
          <div className='flex items-center  justify-center flex-col space-y-1  w-[70%] sm:w-[60%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%]  '>
            <p className=' text-sm text-center pb-6 font-sans font-bold' style={{ fontFamily: 'Coolvetica Compressed Heavy, sans-serif', fontWeight: '700' }}>Insére ton adresse e-mail afin de réinitialiser ton mot de passe !</p>
            <form onSubmit={handleSubmit} className='items-center flex justify-center flex-col space-y-4 w-[100%] sm:w-[70%] md:w-[70%] lg:w-[70%] xl:w-[70%] 2xl:w-[70%]'>

              <CustomInput
                icon={<svg
                  className="w-6 h-6 text-black   "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 16"

                >
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>}
                styleinput="pl-16 font-medium py-2"
                style='pl-5 '
                placeholder='Adresse e-mail'
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}

              />

              <div className='w-full'>
                {/* <Link to="/connexion">   */}
                <CustomButton
                  icon={<img src={send} className="w-6 h-6 text-black  " />}
                text='Envoyer'
                styleinput="bg-gray-300 pl-14 gap-8 text-[15px]  font-medium"

                />
                {/* </Link> */}
              </div>
            </form>


          </div>
        </div>
        <div className='mt-6'>
          <CustomFooter styelefooter='text-[15px]  mx-1  font-bold' />
        </div>

      </div>
    </section>

  )
}

export default ForgetPasswordPage
