

// export default StackedBarChart;
// import React, { useEffect, useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import { getUsersFromSameTeamandclassment } from '../../../Api/authApi.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const StackedBarChart = ({ userId }) => {
//     const [data, setData] = useState({
//         labels: [],  // Labels des semaines
//         datasets: [], // Données des utilisateurs par semaine
//     });
//     const predefinedColors = ['#545454', '#737373', '#a6a6a6', '#FFF'];

//     useEffect(() => {
//         const fetchTeamData = async () => {
//             try {
//                 const response = await getUsersFromSameTeamandclassment(userId);

//                 // Vérifiez la validité de la réponse API
//                 if (!response || !response.semaine_periodes) {
//                     console.error("La réponse API est invalide ou manquante.");
//                     return;
//                 }

//                 // S'assurer qu'il y a 4 semaines, en ajoutant des semaines manquantes si nécessaire
//                 const semainePeriodes = response.semaine_periodes.slice(0, 4); // Limiter à 4 semaines (si nécessaire)

//                 // Créer les labels pour les 4 semaines
//                 const labels = semainePeriodes.map((periode) => `Semaine ${periode.semaine}`);

//                 const datasets = [];
//                 const uniqueUsers = {};

//                 // Itérer sur les semaines et collecter les montants pour chaque utilisateur
//                 semainePeriodes.forEach((periode) => {
//                     periode.users_montants.forEach((userMontant) => {
//                         // Si c'est un nouvel utilisateur, on crée un dataset pour lui
// if (!uniqueUsers[userMontant.user_id]) {
//     uniqueUsers[userMontant.user_id] = {
//         label: userMontant.username,
//         data: Array(4).fill(0), // 4 semaines, donc 4 éléments
//         backgroundColor: predefinedColors[Object.keys(uniqueUsers).length % predefinedColors.length],
//     };
//                         }

//                         // Trouver l'index de la semaine et ajouter le montant correspondant
//                         const weekIndex = semainePeriodes.findIndex((p) => p.semaine === periode.semaine);
//                         if (weekIndex >= 0) {
//                             uniqueUsers[userMontant.user_id].data[weekIndex] = parseFloat(userMontant.montant) || 0;
//                         }
//                     });
//                 });

//                 // Ajouter chaque utilisateur unique comme dataset
//                 Object.values(uniqueUsers).forEach((userDataset) => datasets.push(userDataset));

//                 setData({
//                     labels: labels,
//                     datasets: datasets,
//                 });
//             } catch (error) {
//                 console.error("Erreur lors de la récupération des données:", error);
//             }
//         };

//         fetchTeamData();
//     }, [userId]);


//     const options = {
//         layout: {
//             padding: {
//                 top: 1,
//                 bottom: 0,
//             },
//         },

// plugins: {
//     title: {
//         display: true,               // Activer l'affichage du titre
//         text: '',   // Texte du titre
//         font: {
//             family: 'Arial',          // Police de caractère
//             size: 20,                 // Taille de la police
//             style: 'bold',            // Style de la police
//             weight: 'normal'          // Poids de la police
//         },
//         color: '#333',                // Couleur du titre
//         padding: {
//             top: 10,
//             bottom: 20
//         },
//         align: 'center'               // Alignement du titre (gauche, centre, droite)
//     },
//     legend: {
//         display: true,               // Activer l'affichage de la légende
//         position: 'top',             // Position de la légende (haut, bas, gauche, droite)
//         align: 'center',             // Alignement de la légende (début, centre, fin)
//         fullSize: true,              // Élargit la légende pour occuper toute la largeur du graphique
//         reverse: false,              // Inverser l'ordre de la légende si vrai
//         labels: {
//             boxWidth: 4,            // Largeur de la boîte de couleur
//             boxHeight: 4,           // Hauteur de la boîte de couleur
//             color: '#666',           // Couleur des étiquettes
//             font: {
//                 family: 'Arial',     // Police pour le texte de la légende
//                 size: 12,            // Taille de la police pour la légende
//                 style: 'normal',     // Style de la police
//                 lineHeight: 1.2      // Hauteur de ligne pour le texte de la légende
//             },
//             padding: 10,             // Espacement autour des éléments de la légende
//             usePointStyle: true,     // Utilise un style de point pour les icônes de la légende
//             pointStyle: 'circle'     // Forme du point dans la légende (cercle, carré, étoile, etc.)
//         }
//     }
// },

//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: true,
//             },
// y: {
//     stacked: true,
//     beginAtZero: true, // Commence à 0
//     suggestedMax: 80000, // Maximum suggéré pour l'axe Y
//     ticks: {
//         stepSize: 20000, // Incrémenter par 20000
//     },
//             },
//         },
//     }

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };

// export default StackedBarChart;
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getUsersFromSameTeam } from '../../../Api/authApi.js'; // Assurez-vous que cette fonction API est définie correctement

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedBarChart = ({ userId }) => {
    const [data, setData] = useState({
        labels: [], // Labels des semaines
        datasets: [], // Données des utilisateurs par semaine
    });
    const predefinedColors = ['#545454', '#737373', '#a6a6a6', '#FFF'];

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const response = await getUsersFromSameTeam(userId); // Récupérer les données de l'API

                if (!response || !response.semaine_periodes) {
                    console.error("La réponse API est invalide ou manquante.");
                    return;
                }

                const semainePeriodes = Object.entries(response.semaine_periodes); // Transformer en tableau [clé, valeur]

                const labels = semainePeriodes.map(([key, periode]) => key); // "Semaine 1", "Semaine 2", ...
                const userMontants = {};
                const datasets = [];

                // Itérer sur les semaines pour collecter les données
                semainePeriodes.forEach(([key, periode], weekIndex) => {
                    periode.users.forEach((user) => {
                        if (!userMontants[user.user_id]) {
                            // Ajouter un utilisateur unique avec des montants initialisés à 0
                            userMontants[user.user_id] = {
                                label: user.user_name,
                                data: Array(semainePeriodes.length).fill(0),
                                backgroundColor: predefinedColors[Object.keys(userMontants).length % predefinedColors.length],
                            };
                        }

                        // Ajouter le montant de l'utilisateur pour la semaine correspondante
                        userMontants[user.user_id].data[weekIndex] = parseFloat(user.montant) || 0;
                    });
                });

                // Ajouter les utilisateurs uniques comme datasets
                Object.values(userMontants).forEach((userDataset) => datasets.push(userDataset));

                setData({
                    labels,
                    datasets,
                });
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            }
        };

        fetchTeamData();
    }, [userId]);

    const options = {
        plugins: {
            title: {
                display: true,               // Activer l'affichage du titre
                text: '',   // Texte du titre
                font: {
                    family: 'Arial',          // Police de caractère
                    size: 20,                 // Taille de la police
                    style: 'bold',            // Style de la police
                    weight: 'normal'          // Poids de la police
                },
                color: '#333',                // Couleur du titre
                padding: {
                    top: 10,
                    bottom: 20
                },
                align: 'center'               // Alignement du titre (gauche, centre, droite)
            },
            legend: {
                display: true,               // Activer l'affichage de la légende
                position: 'top',             // Position de la légende (haut, bas, gauche, droite)
                align: 'center',             // Alignement de la légende (début, centre, fin)
                fullSize: true,              // Élargit la légende pour occuper toute la largeur du graphique
                reverse: false,              // Inverser l'ordre de la légende si vrai
                labels: {
                    boxWidth: 4,            // Largeur de la boîte de couleur
                    boxHeight: 4,           // Hauteur de la boîte de couleur
                    color: '#666',           // Couleur des étiquettes
                    font: {
                        family: 'Arial',     // Police pour le texte de la légende
                        size: 12,            // Taille de la police pour la légende
                        style: 'normal',     // Style de la police
                        lineHeight: 1.2      // Hauteur de ligne pour le texte de la légende
                    },
                    padding: 10,             // Espacement autour des éléments de la légende
                    usePointStyle: true,     // Utilise un style de point pour les icônes de la légende
                    pointStyle: 'circle'     // Forme du point dans la légende (cercle, carré, étoile, etc.)
                }
            }
        },

        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: 'gray', // Couleur des étiquettes
                    font: {
                        size: 12,
                        family: 'Arial',
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                // min: 0,
                ticks: {
                    stepSize: 10000,
                },
            },
            
                      
        },
        
        labels: {
            boxWidth: 4,            // Largeur de la boîte de couleur
            boxHeight: 4,           // Hauteur de la boîte de couleur
            color: '#666',           // Couleur des étiquettes
            font: {
                family: 'Arial',     // Police pour le texte de la légende
                size: 12,            // Taille de la police pour la légende
                style: 'normal',     // Style de la police
                lineHeight: 1.2      // Hauteur de ligne pour le texte de la légende
            },
            padding: 10,             // Espacement autour des éléments de la légende
            usePointStyle: true,     // Utilise un style de point pour les icônes de la légende
            pointStyle: 'circle'     // Forme du point dans la légende (cercle, carré, étoile, etc.)
        }
    };

    return (
        <div style={{ width: '100%', height: '60vh' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default StackedBarChart;
