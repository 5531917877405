
// export default ProgressiveLineChart;
// import React, { useState, useEffect, useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';
// import Loader from "../../Loader";

// const API_URL = 'http://127.0.0.1:8000/api';
// // const API_URL = 'https://tradingseries.fr/admin/public/api';
// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// const ProgressiveLineChart = ({ marcheId, leagueid, selectedTime = '1m' ,oading, onDataLoaded}) => { // Valeur par défaut pour `selectedTime`
//   const [data, setData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const chartRef = useRef();
//   const [loading, setLoading] = useState(false);

//   const fetchData = async () => {
//     setLoading(true);

//     const url = `${API_URL}/getDatabaydatevirtuel/${marcheId}/${leagueid}/${selectedTime || '1m'}`; // Définit '5h' par défaut
//     try {
//       const response = await axios.get(url);
//       const fetchedData = response.data;
//       if (fetchedData && fetchedData.values) {
//         const values = [];
//         const labels = [];
//         let currentDateLabel = null;

//         // Traiter les données selon l'intervalle
//         if (['5h', '1j'].includes(selectedTime)) {
//           fetchedData.values.forEach(day => {
//             day.values.forEach(minuteData => {
//               const label = `${day.jour_dans_mois_virtuel}/${day.mois_virtuel}/${fetchedData.annee_virtuelle}`;
//               labels.push(label);
//               values.push(Number(minuteData.value));
//             });
//           });
//         } else if (['1w', '1m'].includes(selectedTime)) {
//           // Pour `1w` et `1m`, afficher la date une seule fois par jour
//           fetchedData.values.forEach(day => {
//             const dayLabel = `${day.jour_dans_mois_virtuel}/${day.mois_virtuel}/${fetchedData.annee_virtuelle}`;
//             day.values.forEach((minuteData, index) => {
//               // Ajouter la date uniquement au début de chaque jour
//               if (index === 0 && currentDateLabel !== dayLabel) {
//                 labels.push(dayLabel);
//                 currentDateLabel = dayLabel;
//               } else {
//                 labels.push(''); // Labels vides pour éviter la répétition de la date
//               }
//               values.push(Number(minuteData.value));
//             });
//           });


//         } else if (selectedTime === '1y') {
//           fetchedData.values[0].mois_values.forEach(month => {
//             const monthLabel = `${month.mois}/${fetchedData.annee_virtuelle}`;
//             month.values.forEach((hourData, index) => {
//               if (index === 0 && currentDateLabel !== monthLabel) {
//                 labels.push(monthLabel);
//                 currentDateLabel = monthLabel;
//               } else {
//                 labels.push('');
//               }
//               values.push(Number(hourData.value));
//             });
//           });
//         } else if (['5y', '10y', '25y'].includes(selectedTime)) {
//           fetchedData.values.forEach(yearData => {
//             yearData.mois_values.forEach(monthData => {
//               const monthLabel = `${monthData.mois}/${yearData.annee_virtuelle}`;
//               monthData.values.forEach(hourData => {
//                 labels.push(monthLabel); // Ajouter la date pour l’axe X seulement
//                 values.push(Number(hourData.value));
//               });
//             });
//           });
//         }

//         setData(values);
//         setLabels(labels);
//       } else {
//         setData([]);
//         setLabels([]);
//       }
//     } catch (error) {
//       // console.error('Erreur lors de la récupération des données:', error);
//     } finally {
//       onDataLoaded(); // Informer le parent que les données sont chargées
//     }

//   };

//   useEffect(() => {
//     fetchData();
//   }, [selectedTime, marcheId, leagueid, onDataLoaded]);

//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         borderColor: 'gray',
//         borderWidth: 0.5,
//         radius: 0.5,
//         data: data,
//       },
//     ],
//   };

//   const options = {
//     animation: false,
//     interaction: {
//       intersect: false,
//       mode: 'nearest',
//     },
//     plugins: {
//       legend: {
//         display: false,
//       },
//       tooltip: {
//         callbacks: {
//           title: () => null, // Supprime le titre du tooltip pour masquer la date
//           label: (context) => `${context.raw}`,
//         },
//         displayColors: false,
//       },
//     },
//     scales: {
//       x: {
//         type: 'category',
//         labels: labels,
//         grid: { display: false },
//         ticks: {
//           display: true,
//           font: { size: 8 },
//           callback: (value, index) => labels[index] ? labels[index] : '', // Affiche seulement les dates non vides
//         },
//       },
//       y: {
//         grid: { display: false },
//         ticks: { display: false, font: { size: 10 } },
//       },
//     },
//   };

//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <div style={{ position: 'relative', width: '100%', height: '100%' }}>

//         <Line ref={chartRef} data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ProgressiveLineChart;
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import Loader from "../../Loader";

// const API_URL = 'http://127.0.0.1:8000/api'; // Changez cette URL selon vos besoins
 const API_URL = 'https://tradingseries.fr/admin/public/api';

const ProgressiveLineChart = ({ marcheId, leagueid, selectedTime = '1m' }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const url = `${API_URL}/getDatabayPeriodvertuelv2/${marcheId}/${leagueid}/${selectedTime}`;
        const response = await axios.get(url);

        const dataFromAPI = response.data;
        // console.log("Données brutes de l'API :", dataFromAPI);

        // Pas de formatage complexe ici, juste passer les données brutes
        setChartData(dataFromAPI.values);
      } catch (err) {
        setError('Erreur lors de la récupération des données.');
        // console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedTime, marcheId, leagueid]);

  // Options pour ApexCharts
  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: { enabled: false },
      background: '#252f3d', // Change background color to dark gray
      offsetX: 1, // Ajoute de l'espace sur la gauche
      offsetY: 1, // Ajoute de l'espace en haut/bas
    
    },
    colors:'gray', // Couleur du trait (rouge/orange dans cet exemple)

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2, // Épaisseur du trait

    },
    xaxis: {
      type: 'category',
      title: {
      },
      labels: {
        rotate: -80, // Rotate labels for better readability
        style: {
          colors: "#fff", // Couleur des labels
          fontSize: "8px",
        },
        hideOverlappingLabels: true, // Masque les labels qui se chevauchent

        formatter: (value) => {
          const date = new Date(value);
          const frenchMonths = [
           'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin',
          'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'
          ];
          return `${date.getDate()} ${frenchMonths[date.getMonth()]} ${date.getFullYear()}`;
        },
      },
    },
    grid: {
      show:false
    },
    yaxis: {
      show: false, // Hide the Y-axis
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy HH:mm', // Format du tooltip pour l'axe X
      },
      y: {
        formatter: (value) => value.toFixed(2), // Formater les valeurs dans le tooltip
      },
    },
    theme: {
      mode: 'dark', // Thème sombre
    },
  };

  const series = [
    {
      name: '',
      data: chartData.flatMap((day) =>
        day.minutes.map((minute) => ({
          x: new Date(
            minute.annee_virtuelle,
            minute.mois_virtuel - 1,
            minute.jour_virtuel,
            parseInt(minute.minute.split(':')[0], 10),
            parseInt(minute.minute.split(':')[1], 10)
          ).getTime(),
          y: parseFloat(minute.value),
        }))
      ),
    },
  ];

  if (loading) return  <Loader size={120} /> // Affiche le loader pendant le chargement
  ;

  return (
    <div>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default ProgressiveLineChart;
