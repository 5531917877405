/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
// import React, { useState, useEffect } from 'react';

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Importer useNavigate pour la redirection
import { getValidMarketsByUserLeague } from '../../Api/authApi.js';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import { setSelectedMarket } from '../../redux/marketSlice.js'; // Importer l'action Redux
import { addFavoriteMarket, removeFavoriteMarket } from '../../redux/marketSlice'; // Importez ici les actions

import { FaRegStar, FaStar } from "react-icons/fa";

const PorteFeuilleBitcoin = () => {
    const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
    const user_id = useSelector((state) => state.auth.user.id); // Récupérer l'ID utilisateur depuis le store Redux
    const [selectedCountry, setSelectedCountry] = useState('FRA'); // Par défaut, FRA est sélectionné
    const [ValidMarkets, setValidMarkets] = useState([]);
    const [loading, setLoading] = useState(false); // Indicateur de chargement
    const [errorMessage, setErrorMessage] = useState(''); // Message d'erreur
    const countries = ['FRA', 'USA', 'GB']; // Liste des pays fixes

    const dispatch = useDispatch(); // Utiliser dispatch pour envoyer des actions Redux
    const navigate = useNavigate(); // Utiliser navigate pour rediriger vers une autre page
    const favoriteMarkets = useSelector((state) => state.market.favoriteMarkets); // Accéder aux marchés favoris

    // Fonction pour récupérer les données de marché en fonction du pays sélectionné
    const fetchMarketData = async (pays) => {
        setValidMarkets([]); // Vider les données actuelles
        setErrorMessage(''); // Réinitialiser l'erreur
        setLoading(true); // Définir l'état de chargement

        try {
            const data = await getValidMarketsByUserLeague(user_id, type, pays); // Appeler avec user_id, type, et pays
            if (data.valid_marches.length > 0) {
                setValidMarkets(data.valid_marches); // Mettre à jour les marchés si des données sont trouvées
                setErrorMessage(''); // Réinitialiser l'erreur si des données sont disponibles
            } else {
                setValidMarkets([]); // Vider les marchés s'il n'y a pas de données
                setErrorMessage(`Aucun marché disponible pour ${pays}.`); // Message si pas de données
            }
        } catch (error) {
            setErrorMessage(`Aucun marché disponible pour ${pays}.`); // Message si pas de données
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    // Fonction de gestion du clic sur un pays
    const handleCountryClick = (pays) => {
        setSelectedCountry(pays); // Mettre à jour le pays sélectionné
        fetchMarketData(pays); // Appeler la fonction pour récupérer les données
    };

    // Fonction pour gérer le clic sur un marché
    // const handleMarketClick = (market) => {
    //     navigate('/portefeuille_menu', { state: { selectedFavorite: market } });
    // };
    const handleMarketClick = (market) => {
        navigate(`/portefeuille_menu/${market.marche_id}/${market.league_id}`);
    };

    const toggleFavoriteMarket = (market) => {
        if (favoriteMarkets.find(fav => fav.marche_id === market.marche_id)) {
            dispatch(removeFavoriteMarket(market)); // Supprimer si déjà dans les favoris

        } else {
            dispatch(addFavoriteMarket(market)); // Ajouter si pas dans les favoris
            // navigate('/portefeuille_menu', { state: { selectedFavorite: market } });
            navigate(`/portefeuille_menu/${market.marche_id}/${market.league_id}`);


        }
    };


    // useEffect pour charger par défaut les données pour "FRA" à l'initialisation
    useEffect(() => {
        fetchMarketData('FRA'); // Charger les données pour FRA par défaut au chargement initial
    }, [type]); // Se déclenche à l'initialisation ou lorsque le type change

    // useEffect pour rafraîchir les données toutes les minutes pour le pays sélectionné
    // hadi li badlt
    // useEffect(() => {
    //     if (selectedCountry && type) {
    //         const interval = setInterval(() => {
    //             fetchMarketData(selectedCountry); // Rafraîchir les données toutes les minutes
    //         }, 300000); // 60 000 ms = 1 minute

    //         return () => clearInterval(interval); // Nettoyer l'intervalle
    //     }
    // }, [selectedCountry, type]);

    // Calcul du pourcentage de différence
    const calculatePercentage = (difference, lastValue) => {
        if (!lastValue || lastValue == 0) return 0; // Éviter la division par zéro
        return ((difference / lastValue) * 100).toFixed(2); // Calculer le pourcentage
    };

    // Déterminer la classe CSS en fonction de la différence (positif ou négatif)
    const getChangeColor = (difference) => {
        return difference > 0 ? 'text-green-500' : 'text-red-500';
    };

    return (
        <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
            <div className="grid lg:grid-cols-1 md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center gap-7 sm:px-10 md:px-16">
                <div className="lg:w-[98%] w-[96%] lg:p-4 p-2 lg:border md:border sm:border rounded-lg shadow bg-[#252f3d] text-white flex mx-auto flex-col">
                    <div className="flex flex-row my-3">
                        {countries.map((pays) => (
                            <button
                                key={pays}
                                onClick={() => handleCountryClick(pays)}
                                className={`border rounded-xs w-1/3 justify-center items-center cursor-pointer ${selectedCountry === pays ? 'bg-white text-black' : 'bg-[#252f3d] text-gray-300'
                                    } font-normal lg:text-[15px] lg:font-medium text-[13px] text-center px-4 py-1.5 2xl:p-2 xl:p-2 lg:p-2 md:p-2`}
                            >
                                {pays}
                            </button>
                        ))}
                    </div>

                    {/* Si en cours de chargement, afficher un message de chargement */}
                    {loading && <p>Chargement des marchés en cours...</p>}
                    <h3>{selectedCountry}</h3> {/* Afficher le pays */}
                    {/* Si des données sont présentes */}
                    {!loading && ValidMarkets.length > 0 && (
                        <div>
                            {ValidMarkets.map((market) => (
                                <div
                                    key={market.marche_id}
                                    className="flex justify-between px-3 py-3 border-b border-gray-500"
                                onClick={() => handleMarketClick(market)} // Gérer le clic sur un marché
                                >
                                    <div className="flex gap-2 items-center">
                                        <div className="relative">
                                            {/* <FaRegStar fontSize={22} className='-top-1 absolute '  /> */}
                                            {favoriteMarkets.find(fav => fav.marche_id === market.marche_id) ? (
                                                <FaStar
                                                    fontSize={22}
                                                    className="text-yellow-500 -top-1 absolute"
                                                    onClick={() => toggleFavoriteMarket(market)}
                                                />
                                            ) : (
                                                <FaRegStar
                                                    fontSize={22}
                                                    className="-top-1 absolute text-white"
                                                    onClick={() => toggleFavoriteMarket(market)}
                                                />
                                            )}


                                            <img    src={`https://tradingseries.fr/admin/storage/app/public/${market.icon}`}className="w-14 h-14 rounded-full" alt={market.titre} />
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-[17px] lg:text-md font-bolder">{market.titre}</p>
                                            <p className="text-[12px] lg:text-md font-normal text-gray-300 flex gap-2 items-center ">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="red" className="bi bi-clock-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                                    </svg>
                                                </span>{market.heure_complete}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center flex-col font-medium">
                                        <p className="text-[14px] lg:text-md ">{parseFloat(market.current_value).toFixed(3)}€</p>
                                        <p className={`text-[14px] lg:text-md ${getChangeColor(market.difference)}`}>
                                            {market.difference > 0 ? '+' : ''}{calculatePercentage(market.difference, market.last_value)}%
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Si pas de données et erreur, afficher le message d'erreur */}
                    {!loading && ValidMarkets.length === 0 && errorMessage && (
                        <div className="text-center py-5">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PorteFeuilleBitcoin;
