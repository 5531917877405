
// import React, { useState, useEffect } from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import { getUsersFromuser } from '../../../Api/authApi.js'; // Assurez-vous que la fonction API est correctement définie

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const BarChart = ({ userId }) => {
//     const [dataValues, setDataValues] = useState([]); // Montants par semaine
//     const [labels, setLabels] = useState([]); // Semaines
//     const [colors, setColors] = useState([]); // Couleurs des barres

//     useEffect(() => {
//         const fetchMontantsData = async () => {
//             try {
//                 const response = await getUsersFromuser(userId); // Récupérer les données de l'utilisateur
//                 if (!response || !response.semaine_periodes) {
//                     return;
//                 }

//                 const semainePeriodes = response.semaine_periodes; // Périodes des semaines

//                 // Extraire les labels (semaines)
//                 const tempLabels = semainePeriodes.map((item, index) => item.semaine); // Utilisation du nom de la semaine

//                 // Extraire les montants
//                 const tempDataValues = semainePeriodes.map(item => {
//                     const montant = parseFloat(item.montant_par_semaine); // Conversion du montant en nombre
//                     return isNaN(montant) ? 0 : montant; // Gérer les montants nuls
//                 });

//                 const tempColors = semainePeriodes.map(item => {
//                     const montant = parseFloat(item.montant_par_semaine); // Conversion du montant en nombre
                    
//                     // Vérifier le statut de la semaine
//                     if (item.status === 'En cours') {
//                         return montant > 10000 ? 'green' : 'red'; // En cours et montant > 10 000 = vert, sinon rouge
//                     } else if (item.status === 'Terminée') {
//                         return montant > 10000 ? 'green' : 'red'; // Terminée et montant > 10 000 = vert, sinon rouge
//                     } else if (item.status === 'À venir') {
//                         return 'gray'; // Semaine à venir = gris
//                     }
//                     return 'yellow'; // Par défaut, couleur jaune pour d'autres cas (en cours par exemple)
//                 });

//                 // Mettre à jour les états
//                 setLabels(tempLabels);
//                 setDataValues(tempDataValues);
//                 setColors(tempColors);
//             } catch (error) {
//                 console.error('Erreur lors de la récupération des données :', error);
//             }
//         };

//         fetchMontantsData();
//     }, [userId]);

//     const data = {
//         labels: labels, // Semaines
//         datasets: [
//             {
//                 label: '',
//                 data: dataValues, // Montants par semaine
//                 backgroundColor: colors, // Couleurs basées sur le statut
//                 borderWidth: 1,
//                 borderRadius: 5,
//             },
//         ],
//     };
//     const options = {
//                 plugins: {
//                     title: {
//                         display: true,
//                         text: '',
//                     },
//                     legend: {
//                         display: false,
//                     },
//                 },
//                 responsive: true,
//                 maintainAspectRatio: false,
//                 scales: {
//                     x: {
//                         stacked: false,
//                         grid: {
//                             display: false,
//                         },
//                     },
//                     y: {
//                         stacked: false,
//                         grid: {
//                             display: false,
//                         },
//                         suggestedMax: Math.max(...dataValues) + 500, // Ajuster l'axe Y
//                     },
//                 },
//             };
//     return (
//         <div>
//             <Bar data={data} />
//         </div>
//     );
// };

// export default BarChart;
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getUsersFromuser } from '../../../Api/authApi.js'; // Assurez-vous que cette fonction API est définie correctement

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ userId }) => {
    const [dataValues, setDataValues] = useState([]); // Montants par semaine
    const [labels, setLabels] = useState([]); // Semaines
    const [colors, setColors] = useState([]); // Couleurs des barres

    useEffect(() => {
        const fetchMontantsData = async () => {
            try {
                const response = await getUsersFromuser(userId); // Récupérer les données de l'utilisateur connecté
                if (!response || !response.semaine_periodes) {
                    return;
                }

                const semainePeriodes = Object.values(response.semaine_periodes); // Obtenir les valeurs des semaines

                // Extraire les labels, montants et couleurs
                const tempLabels = [];
                const tempDataValues = [];
                const tempColors = [];

                semainePeriodes.forEach((item, index) => {
                    // Ajouter les noms des semaines (e.g., "Semaine 1", "Semaine 2")
                    tempLabels.push(`Semaine ${index + 1}`);

                    // Ajouter le montant
                    const montant = parseFloat(item.montant) || 0;
                    tempDataValues.push(montant);

                    // Définir les couleurs en fonction du statut
                    if (item.status === 'Valide') {
                        tempColors.push(montant > 10000 ? 'green' : 'red');
                    } else if (item.status === 'Terminée') {
                        tempColors.push(montant > 10000 ? 'green' : 'red');
                    } else if (item.status === 'En cours') {
                        tempColors.push('green');
                    } else {
                        tempColors.push('green'); // Couleur par défaut
                    }
                });

                // Mettre à jour les états
                setLabels(tempLabels);
                setDataValues(tempDataValues);
                setColors(tempColors);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchMontantsData();
    }, [userId]);

    const data = {
        labels: labels, // Semaines
        datasets: [
            {
                label: '',
                data: dataValues, // Montants
                backgroundColor: colors, // Couleurs des barres
                borderWidth: 1,
                borderRadius: 5,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: '',
            },
            legend: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: false,
                grid: {
                    display: true,
                },
                suggestedMax: Math.max(...dataValues, 10000) + 1000, // Ajuster l'axe Y
            },
        },
    };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
