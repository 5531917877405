import React, { useState } from 'react';
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'

import CustomInput from '../../components/custominput'
import key from '../../images/key.png'
import send from '../../images/send.svg'
import CustomButton from '../../components/custombutton'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserTeam } from '../../Api/authApi.js';
import { toast } from 'react-toastify';

import { updateUser } from '../../redux/authSlice'; // Importez l'action updateUser

const Updateequipe = () => {

    const [teamCode, setTeamCode] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user); // Assurez-vous que l'état utilisateur est correctement configuré.
    const handleUpdate = async () => {
        try {
            const response = await updateUserTeam(user.id, teamCode);
            if (response.success) {
                toast.success(response.message);

                dispatch(updateUser({ team_id: response.team_id }));
                navigate('/profil');

            } else {
                setMessage(response.message || 'Erreur lors de la mise à jour de l\'équipe.');
            }
        } catch (error) {
            setMessage(error.message || 'Erreur lors de la mise à jour de l\'équipe.');
            toast.warning(error.message);
        }
    };

    return (
        <section className=' bg-[#252f3d] text-white relative'>
            <Link className='absolute left ' to='/profil'>
                <img className={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
            </Link>
            <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">

                <div className='flex items-center flex-col justify-center  '>
                    <div className='flex  justify-center h-[220px] flex-col items-center'>
                        <CustomHeader width="w-[50%] mt-9" />
                    </div>                    <div className='flex items-center flex-col space-y-2   w-[90%] sm:w-[60%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
                        <p className='font-medium  text-center text-sm py-4'>Change d'équipe dès maintenant !</p>
                        <div className=' flex-col gap-4 flex items-center my-2'>
                            <div className="relative w-[100%] lg:w-full items-center flex justify-center ">
                                <input
                                    type="text"
                                    value={teamCode}
                                    name='team_code'
                                    onChange={(e) => setTeamCode(e.target.value)} className={` pl-14 pr-4 py-2 border rounded-sm text-black  text-[12px]  md:text-[14px]  sm:text-[14px]  lg:text-[14px]  xl:text-[14px] 2xl:text-[14px]   w-[100%] placeholder-black font-medium `}
                                    placeholder="Nouveau code d&rsquo;équipe" />
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center  pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="black" className="bi bi-people-fill" viewBox="0 0 16 16">
                                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                </svg>
                            </div>
                        </div>
                        {/* <Link to='/profil' className='w-full  items-center flex justify-center  '> */}
                        <button onClick={handleUpdate} className={` hover:bg-gray-400  border rounded-sm text-black  w-[100%] lg:w-full  py-1.5 flex pl-3  gap-6   bg-gray-300 font-medium text-[13px] text-center items-center  `}>
                            <span><svg className="w-6 h-6 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                            </svg></span>
                        <span className='text-sm'>Confirmer</span>
                    </button>
                    {/* </Link> */}
                </div>

            </div>
            <div className='flex items-center flex-col space-y-7 justify-center mt-8'>
                <CustomFooter styelefooter='text-justify mx-9 text-[14.5px]' />

            </div>

        </div>

            </div >
        </section >

    )
}

export default Updateequipe