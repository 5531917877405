



// v1

// import React, { useState, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";
// import Loader from "../../Loader";

// const StockChart = ({ marcheId, leagueid }) => {
//   const [series, setSeries] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedInterval, setSelectedInterval] = useState("6h");

//   const intervals = {
//     "6h": "6h",
//     "1j": "1j",
//     "1w": "1w",
//     "1m": "1m",
//     "Max": "1m",
//   };
//    const API_URL = "http://localhost:8000/api/"; // URL de l'API

//   // const API_URL = "https://tradingseries.fr/admin/public/api/";

//   // Fetch and process data
//   const fetchData = async (interval) => {
//     setLoading(true);
//     try {
//       const url = `${API_URL}getDatabayPeriodvertuelbougiev2/${marcheId}/${leagueid}/${interval}`;
//       const response = await fetch(url);
//       // console.log('data',response)

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       const data = await response.json();
//       if (data.values && data.values.candles.length > 0) {
//         const processedData = data.values.candles.map((item) => {
//           const { minute, mois_virtuel, jour_virtuel, annee_virtuelle } = item.x;

//           // Ensure unique timestamps for each data point
//           const formattedX = `${annee_virtuelle}-${String(mois_virtuel).padStart(2, "0")}-${String(jour_virtuel).padStart(2, "0")} ${minute}`;

//           return {
//             x: formattedX, // Unique timestamp for each data point
//             y: item.y, // Candlestick data [open, high, low, close]
//           };
//         });
//         setSeries([{ data: processedData }]);
//       } else {
//         // console.warn("No data available for this interval");
//         setSeries([]);
//       }
//     } catch (error) {
//       // console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData(selectedInterval);
//   }, [selectedInterval, marcheId, leagueid]);

//   const options = {
//     chart: {
//       type: "candlestick",
//       toolbar: {
//                 show: false,
//               },
//       height: 300,
//       plotOptions: {
//         candlestick: {
//           wick: {
//             useFillColor: true, // Utilise la même couleur que la bougie pour la mèche
//           },
//         },
//       },
//       // offsetX: 10, // Ajoute de l'espace sur la gauche
//       //       offsetY: 10, // Ajoute de l'espace en haut/bas
//       // zoom: {
//       //   enabled: true,
//       //   type: "x",
//       //   autoScaleYaxis: true,
//       // },
//     },
//     plotOptions: {
//       candlestick: {
//         wick: {
//           useFillColor: true, // Les mèches utiliseront la même couleur que le corps
//         },
//         colors: {
//           upward: "#FF0000", // Vert pour les bougies haussières
//           // downward: "#FF0000", // Rouge pour les bougies baissières
//           downward: "#00FF00", // Rouge pour les bougies baissières
//         },
//         // barWidth: "50%", // Ajuste la largeur des bougies en pourcentage de l'espace total
//         // barHeight: "70%", // Ajuste la hauteur des bougies en pourcentage (optionnel)
//       },
//     },
    
//     xaxis: {
//       type: "datetime", // Assurez-vous que l'axe est basé sur des catégories
//       labels: {
//         rotate: 0, // Rotation légère des labels pour éviter les chevauchements
//         style: {
//           colors: "#ffffff", // Couleur blanche pour les labels
//           fontSize: "10px",
//         },
//         // formatter: function (value) {
//         //   // Formatage des dates en "jour mois"
//         //   const date = new Date(value);
//         //   const day = date.getDate();
//         //   const monthShort = [
//         //     "Janv.",
//         //     "Févr.",
//         //     "Mars",
//         //     "Avr.",
//         //     "Mai",
//         //     "Juin",
//         //     "Juil.",
//         //     "Août",
//         //     "Sept.",
//         //     "Oct.",
//         //     "Nov.",
//         //     "Déc.",
//         //   ][date.getMonth()];
//         //   return `${day} ${monthShort}`;
//         // },
//         formatter: (value) => {
//           const date = new Date(value); // Convertit la valeur en objet Date
//           const day = date.getDate(); // Récupère le jour
//           const monthShort = [
//             "Janv.",
//             "Févr.",
//             "Mars",
//             "Avr.",
//             "Mai",
//             "Juin",
//             "Juil.",
//             "Août",
//             "Sept.",
//             "Oct.",
//             "Nov.",
//             "Déc.",
//           ][date.getMonth()]; // Récupère le mois
//           const year = date.getFullYear(); // Récupère l'année
//           return `${day} ${monthShort} ${year}`; // Formatte en "Jour Mois Année"
//         },
        
//         offsetX: 8, // Décale les labels vers la droite

//       },
//       tickAmount: 7, // Limitez à 7 ticks visibles (ou ajustez selon vos besoins)
//       axisTicks: { show: false }, // Cache les petites marques de tick
//       axisBorder: { show: false }, // Cache la bordure de l'axe
//       padding: {
//         left: 10, // Ajoute un espace de 30px à gauche
//         right: 10, // Ajoute un espace de 30px à droite
//       },
//     },
    
//     yaxis: {
//       show: false, // Masque l'axe Y entier (labels, ticks, bordures)

//       tooltip: { enabled: true },
//     },
//     grid: {
//       show:false,
//       // padding: {
//       //   left:1,
//       //   right:5,
//       // },
//     },
//     tooltip: {
//       theme: "dark",
//       x: {
//         format: "dd MMM yyyy HH:mm", // Include hour and minute for clarity
//       },
//     },
//   };

//   const buttonStyle = {
//     padding: "5px 5px",
//     backgroundColor: "#252f3d",
//     color: "gray",
//     borderRadius: "5px",
//     margin: "5px",
//     border: "none",
//   };

//   const selectedButtonStyle = {
//     ...buttonStyle,
//     color: "white",
//   };

//   return (
//     <div className=" mx-2">
//       {loading ? (
//         <Loader size={120} />
//       ) : (
//         <ReactApexChart
//           options={options}
//           series={series}
//           type="candlestick"
//           height={300}

      
//         />
//       )}

//       <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
//         {Object.keys(intervals).map((interval) => (
//           <button
//             key={interval}
//             style={selectedInterval === interval ? selectedButtonStyle : buttonStyle}
//             onClick={() => setSelectedInterval(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default StockChart;


// import React, { useState, useEffect } from "react";
// import { VictoryChart, VictoryCandlestick, VictoryAxis, VictoryTheme,VictoryLabel } from "victory";

// const StockChart = ({ marcheId, leagueid }) => {
//   const [series, setSeries] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedInterval, setSelectedInterval] = useState("6h");
//   const [hoveredCandle, setHoveredCandle] = useState(null); // État pour la bougie survolée
//   const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 }); // Position du tooltip

//   const intervals = {
//     "6h": "6h",
//     "1j": "1j",
//     "1w": "1w",
//     "1m": "1m",
//   };

//   const API_URL = "http://localhost:8000/api/";

//   const fetchData = async (interval) => {
//     setLoading(true);
//     try {
//       const url = `${API_URL}getDatabayPeriodvertuelbougiev2/${marcheId}/${leagueid}/${interval}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();

//       if (data.values && data.values.length > 0) {
//         // Transformation des données avec forçage des mèches
//         const processedData = data.values.map((item) => {
//           const open = item.open;
//           const close = item.close;

//           // Forcer des variations minimes pour high et low si elles sont égales à open et close
//           const high = item.high === Math.max(open, close) ? item.high + 0.2: item.high;
//           const low = item.low === Math.min(open, close) ? item.low - 0.2: item.low;

//           return {
//             x: new Date(item.x), // Convert timestamp to Date object
//             open: open,
//             high: high,
//             low: low,
//             close: close,
//           };
//         });

//         setSeries(processedData);
//       } else {
//         setSeries([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData(selectedInterval);
//   }, [selectedInterval, marcheId, leagueid]);

//   const minY = series.length > 0 ? Math.min(...series.map((d) => d.low)) - 5 : 0;
//   const maxY = series.length > 0 ? Math.max(...series.map((d) => d.high)) + 5 : 100;

//   return (
//     <div className="chart-container">
//       {loading ? (
//         <p>Loading data...</p>
//       ) : (
//         <VictoryChart theme={VictoryTheme.material} scale={{ x: "time" }}>
//           {/* X Axis */}
//           <VictoryAxis
//             style={{
//                // Masque les labels de l'axe Y
//               grid: { stroke: "none" },        // Masque la grille
//             }}
//             tickFormat={(t) =>
//               `${t.getDate()} ${
//                 ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][t.getMonth()]
//               }`
//             }
//           />

//           {/* Y Axis */}
//           <VictoryAxis
//   dependentAxis
//   style={{
//     axis: { stroke: "none" },        // Masque la ligne de l'axe Y
//     ticks: { stroke: "none" },       // Masque les ticks
//     tickLabels: { fill: "none" },    // Masque les labels de l'axe Y
//     grid: { stroke: "none" },        // Masque la grille
//   }}
// />

//           {/* Candlestick Chart */}
//           <VictoryCandlestick
//             data={series}
//             candleWidth={2} // Adjust candle width
//             style={{
//               data: {
//                 stroke: ({ datum }) => (datum.open < datum.close ? "#4CAF50" : "#F44336"), // Couleur des mèches
//                 strokeWidth: 1, // Épaisseur des mèches
//                 fill: ({ datum }) => (datum.open < datum.close ? "#4CAF50" : "#F44336"), // Couleur des corps de bougie
//               },
//             }}
//             candleColors={{
//               positive: "#4CAF50", // Green for bullish candles
//               negative: "#F44336", // Red for bearish candles
//             }}
          
//           />
//         </VictoryChart>
//       )}

//       {/* Interval Selection Buttons */}
//       <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
//         {Object.keys(intervals).map((interval) => (
//           <button
//             key={interval}
//             style={{
//               padding: "5px 10px",
//               backgroundColor: selectedInterval === interval ? "#4CAF50" : "#252f3d",
//               color: "white",
//               borderRadius: "5px",
//               margin: "5px",
//               cursor: "pointer",
//               border: "1px solid #555",
//             }}
//             onClick={() => setSelectedInterval(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default StockChart;


import React, { useState, useEffect } from "react";
import { VictoryChart, VictoryCandlestick, VictoryAxis, VictoryTheme, VictoryTooltip } from "victory";
 import Loader from "../../Loader";

const StockChart = ({ marcheId, leagueid }) => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState("6h");

  const intervals = {
    "6h": "6h",
    "1j": "1j",
    "1w": "1w",
    "1m": "1m",
    "max": "1m",
  };
  const API_URL = "https://tradingseries.fr/admin/public/api/";

  // const API_URL = "http://localhost:8000/api/";

  const fetchData = async (interval) => {
    setLoading(true);
    try {
      const url = `${API_URL}getDatabayPeriodvertuelbougiev2/${marcheId}/${leagueid}/${interval}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.values && data.values.length > 0) {
        const processedData = data.values.map((item, index) => {
          const open = item.open;
          const close = item.close;

          const high = item.high === Math.max(open, close) ? item.high + 0.2: item.high;
          const low = item.low === Math.min(open, close) ? item.low - 0.2: item.low;
          return {
            x: new Date(item.x),
            open: parseFloat(open.toFixed(2)),
            high: parseFloat(high.toFixed(2)),
            low: parseFloat(low.toFixed(2)),
            close: parseFloat(close.toFixed(2)),
            label: ` ${new Date(item.x).getFullYear()}-${(new Date(item.x).getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${new Date(item.x)
              .getDate()
              .toString()
              .padStart(2, '0')}\nOpen: ${open.toFixed(2)}\nHigh: ${high.toFixed(2)}\nLow: ${low.toFixed(2)}\nClose: ${close.toFixed(2)}`, // Tooltip text with full date
            
          };
          
        });

        setSeries(processedData);
      } else {
        console.warn("No candlestick data available");
        setSeries([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedInterval);
  }, [selectedInterval, marcheId, leagueid]);



  return (
    <>
     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
      {loading ? (
      <Loader size={120} />
) : (
        <VictoryChart theme={VictoryTheme.material} scale={{ x: "time" }} domainPadding={{ x: 1 }}  height={700} width={800}
        >
          {/* Axe X */}
          {/* <VictoryAxis
             // Limite le nombre de labels visibles sur l'axe X
            style={{
              grid: { stroke: "none" },
              axis: { stroke: "#FFFFFF" }, // Couleur de l'axe X
              ticks: { stroke: "#FFFFFF", size: 10 }, // Couleur et taille des ticks
              tickLabels: { fill: "#FFFFFF", fontSize: 24, padding: 15 }, // Couleur et style des labels
            }}
          
            tickCount={6} // Affiche un nombre limité de ticks (ajustez selon vos besoins)

            tickValues={series.map((data) => data.x)} // Utilise les dates des bougies comme ticks
            tickFormat={(t) => {
              const date = new Date(t);
              const day = date.getDate();
              const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][date.getMonth()];
              const year = date.getFullYear();
          
          
              return `${day} ${month}\n${year}`; // Format: "Day Month \n Year"
            }}
          /> */}
<VictoryAxis
  style={{
    grid: { stroke: "none" },
    axis: { stroke: "#FFFFFF" },
    ticks: { stroke: "#FFFFFF", size: 1 },
    tickLabels: { fill: "#FFFFFF", fontSize: 20, padding: 10 },
  }}
  tickCount={6} // Affiche un nombre limité de ticks
  tickValues={series.map((data) => data.x)} // Utilise les valeurs X de vos données

  tickFormat={(t) => {
    const date = new Date(t); // Convertir `t` en objet Date
    const day = date.getDate();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][date.getMonth()];
    const year = date.getFullYear(); // Année correctement récupérée

    return `${day} ${month}\n${year}`; // Affiche "Jour Mois \n Année"
  }}
/>

          {/* Axe Y */}
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "none" },
              ticks: { stroke: "none" },
              tickLabels: { fill: "none" },
              grid: { stroke: "none" },
            }}
          />

          {/* Graphique en chandelier */}
          <VictoryCandlestick
            data={series}
            candleWidth={7}
            style={{
              data: {
                stroke: ({ datum }) => (datum.open > datum.close ? "#4CAF50" : "#F44336"), // Inverser les couleurs
                strokeWidth: 1, // Épaisseur des mèches
                fill: ({ datum }) => (datum.open > datum.close ? "#4CAF50" : "#F44336"), // Inverser les couleurs des corps
              },
            }}
            labels={({ datum }) => 
              `Open: ${datum.open.toFixed(2)}\nHigh: ${datum.high.toFixed(2)}\nLow: ${datum.low.toFixed(2)}\nClose: ${datum.close.toFixed(2)}`
            }
                        labelComponent={
              <VictoryTooltip
                style={{ fill: "#FFF", fontSize: 20 }}
                flyoutStyle={{
                  fill: "#252f3d",
                  stroke: "#fff",
                  pointerEvents: "none",
                }}
                pointerLength={0}
                flyoutPadding={{ top: 10, bottom: 10, left: 10, right: 10 }}
                flyoutWidth={200}
                flyoutHeight={150}
                flyoutCornerRadius={5}
              />
            }
          />
        </VictoryChart>
      )}

      {/* Boutons de sélection d'intervalle */}
   
    </div>
    <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
        {Object.keys(intervals).map((interval) => (
        <button
        key={interval}
        style={{
          padding: "5px 5px",
          backgroundColor: "#252f3d", // Le fond reste constant
          color: selectedInterval === interval ? "gray" : "#FFFFFF", // Change uniquement la couleur du texte
          borderRadius: "5px",
          margin: "5px",
          cursor: "pointer",
        }}
        onClick={() => setSelectedInterval(interval)}
      >
        {interval.toUpperCase()}
      </button>
      
        ))}
      </div>
      </>
   
  );
};

export default StockChart;




// import React, { useState, useEffect } from "react";
// import { VictoryChart, VictoryCandlestick, VictoryAxis, VictoryTheme } from "victory";
// import Loader from "../../Loader";

// const StockChart = ({ marcheId, leagueid }) => {
//   const [series, setSeries] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedInterval, setSelectedInterval] = useState("6h");

//   const intervals = {
//     "6h": "6h",
//     "1j": "1j",
//     "1w": "1w",
//     "1m": "1m",
//     "Max": "1m",
//   };

//   const API_URL = "http://localhost:8000/api/";

//   const fetchData = async (interval) => {
//     setLoading(true);
//     try {
//       const url = `${API_URL}getDatabayPeriodvertuelbougiev2/${marcheId}/${leagueid}/${interval}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       if (data.series && data.series[0] && data.series[0].data.length > 0) {
//         const processedData = data.series[0].data.map((item) => ({
//           x: new Date(item.x),
//           open: item.y[0],
//           high: item.y[1],
//           low: item.y[2],
//           close: item.y[3],
//         }));

//         setSeries(processedData);
//       } else {
//         setSeries([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData(selectedInterval);
//   }, [selectedInterval, marcheId, leagueid]);

//   // Calcul des limites dynamiques de l'axe Y
//   const minY = series.length > 0 ? Math.min(...series.map((d) => d.low)) - 0.7 : 0.3;
//   const maxY = series.length > 0 ? Math.max(...series.map((d) => d.high)) + 0.1 : 0.6;
  

//   const buttonStyle = {
//     padding: "10px 15px",
//     backgroundColor: "#252f3d",
//     color: "gray",
//     borderRadius: "5px",
//     margin: "5px",
//     border: "1px solid #555",
//     cursor: "pointer",
//   };

//   const selectedButtonStyle = {
//     ...buttonStyle,
//     backgroundColor: "#4CAF50",
//     color: "white",
//   };

//   return (
//     <div className="mx-2">
//       {loading ? (
//         <Loader size={120} />
//       ) : (
//         <VictoryChart theme={VictoryTheme.material} scale={{ x: "time" }}>
//           {/* Axe X pour afficher les dates */}
//           <VictoryAxis
//             tickFormat={(t) =>
//               `${t.getDate()} ${
//                 ["Janv.", "Févr.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."][
//                   t.getMonth()
//                 ]
//               }`
//             }
//           />

//           {/* Axe Y avec marges dynamiques */}
//           <VictoryAxis dependentAxis domain={[minY, maxY]} />

//           {/* Graphique en chandeliers */}
//           <VictoryCandlestick
//             data={series}
//             candleWidth={3}
//             candleColors={{ positive: "#4CAF50", negative: "#F44336" }}
//             style={{
//               data: {
//                 stroke: "#000000",
//                 strokeWidth: 1,
//                 fill: ({ datum }) => (datum.open > datum.close ? "#F44336" : "#4CAF50"), // Couleur du remplissage

//               },
//             }}
//           />
//         </VictoryChart>
//       )}

//       <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
//         {Object.keys(intervals).map((interval) => (
//           <button
//             key={interval}
//             style={selectedInterval === interval ? selectedButtonStyle : buttonStyle}
//             onClick={() => setSelectedInterval(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default StockChart;









// import React, { useState, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";

// const StockChart = ({ marcheId, leagueid }) => {
//   const [series, setSeries] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedInterval, setSelectedInterval] = useState("6h");

//   const API_URL = "http://localhost:8000/api/";

//   const fetchCandleData = async (interval) => {
//     setLoading(true);
//     try {
//       const url = `${API_URL}/getDatabayPeriodvertuelv2/${marcheId}/${leagueid}/${interval}`;
//       const response = await fetch(url);
//       const data = await response.json();

//       if (data && data.values) {
//         const candles = data.values.flatMap((day) =>
//           day.minutes.map((minuteData) => {
//             const { minute, mois_virtuel, jour_virtuel, annee_virtuelle } = minuteData;

//             const formattedDate = `${jour_virtuel}/${mois_virtuel}/${annee_virtuelle}/${minute}`; // Inclut l'heure

//             return {
//               x: formattedDate,
//               y: [minuteData.open, minuteData.high, minuteData.low, minuteData.close],
//             };
//           })
//         );

//         setSeries([{ data: candles }]);
//       } else {
//         setSeries([]);
//       }
//     } catch (error) {
//       console.error("Erreur lors de la récupération des données :", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCandleData(selectedInterval);
//   }, [selectedInterval, marcheId, leagueid]);

//   const options = {
//     chart: {
//       type: "candlestick",
//       height: 400,
//       background: "#252f3d",
//       toolbar: {
//         show: true,
//       },
//     },
//     xaxis: {
//       type: "category",
//       labels: {
//         formatter: function (value) {
//           const [jour, mois, annee, heure] = value.split("/");
//           const interval = selectedInterval;

//           if (interval === "6h" || interval === "1j") {
//             return `${heure}h ${annee}`;
//           } else if (interval === "1w" || interval === "1m" || interval === "1max") {
//             return `${jour}/${mois}/${annee}`;
//           } else {
//             return value;
//           }
//         },
//         style: {
//           colors: "#fff",
//           fontSize: "10px",
//         },
//       },
//       tickAmount: 8,
//       axisTicks: {
//         show: false,
//       },
//       axisBorder: {
//         show: false,
//       },
//     },
//     yaxis: {
//       labels: {
//         formatter: (value) => value.toFixed(2),
//       },
//     },
//     tooltip: {
//       theme: "dark",
//       x: {
//         format: "dd MMM yyyy",
//       },
//     },
//   };

//   const buttonStyle = {
//     padding: "5px 10px",
//     backgroundColor: "#252f3d",
//     color: "gray",
//     borderRadius: "5px",
//     margin: "2px",
//     border: "none",
//   };

//   const selectedButtonStyle = {
//     ...buttonStyle,
//     color: "white",
//   };

//   return (
//     <div>
//       {loading ? (
//         <p>Chargement...</p>
//       ) : (
//         <ReactApexChart options={options} series={series} type="candlestick" height={400} />
//       )}

//       <div
//         style={{
//           marginTop: "10px",
//           justifyContent: "center",
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         {["6h", "1j", "1w", "1m", "1max"].map((interval) => (
//           <button
//             key={interval}
//             style={
//               selectedInterval === interval ? selectedButtonStyle : buttonStyle
//             }
//             onClick={() => setSelectedInterval(interval)}
//           >
//             {interval.toUpperCase()}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default StockChart;
