import React, { useState, useEffect } from "react";
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'

import CustomInput from '../../components/custominput'
import cleepwd from '../../images/cleepwd.png'
import send from '../../images/send.svg'
import CustomButton from '../../components/custombutton'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import { updateUser } from '../../redux/authSlice'; // Importez l'action 
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../Api/authApi.js';
import { toast } from 'react-toastify';

const UpdatePassword2 = () => {
    const navigate = useNavigate(); // Ajouter cette ligne
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const handleSubmit = async () => {
        if (password !== passwordConfirmation) {
            toast.warning('Les mots de passe ne correspondent pas.');

            return;
        }

        try {
            const id = user.id;
            const data = await updatePassword(id, password, passwordConfirmation);
            toast.success(data.message);
            navigate('/profil');
        } catch (error) {
            toast.warning(error.message);
        }
    };


    return (
        <section className=' bg-[#252f3d] text-white relative'>
            <Link className='absolute left ' to='/connexion'>
                <img className={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
            </Link>
            <div className="bg-[#252f3d] w-full relative overflow-auto h-[100%] lg:pb-auto ">

                <div className='flex items-center flex-col space-y-2 justify-center'>
                    <div className='flex  justify-center h-[220px] flex-col items-center'>
                        <CustomHeader width="w-[50%] mt-9" />
                    </div>                    <div className='flex items-center justify-center flex-col space-y-2  w-[80%] sm:w-[60%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
                        <p className=' font-medium  text-center text-[14px] py-4 '>Modifie ton mot de passe dès maintenant !</p>
                        <div className="relative w-[84%] lg:w-full items-center flex justify-center ">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={` pl-12 pr-4 py-2 border rounded-sm text-black  text-[12px]  md:text-[14px]  sm:text-[14px]  lg:text-[14px]  xl:text-[14px] 2xl:text-[14px]   w-[100%] placeholder-black font-medium `}
                                placeholder="Nouveau mot de passe" />
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center  pointer-events-none">
                                <img src={cleepwd} height="20px" width="20px" ></img>
                            </div>
                        </div>
                        <div className="relative w-[84%] lg:w-full items-center flex justify-center ">
                            <input
                                type="password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                className={` pl-12 pr-4 py-2 border rounded-sm text-black  text-[12px]  md:text-[14px]  sm:text-[14px]  lg:text-[14px]  xl:text-[14px] 2xl:text-[14px]   w-[100%] placeholder-black font-medium `}
                                placeholder="Confirmer mot de passe" />
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center  pointer-events-none">
                                <img src={cleepwd} height="20px" width="20px" ></img>
                            </div>
                        </div>
                        <button className={` hover:bg-gray-400  border rounded-sm text-black  w-[83%] lg:w-full  py-1 flex pl-3  gap-6  bg-gray-300 font-medium text-[13px] text-center items-center  `} onClick={handleSubmit}
                        >
                            <span><svg className="w-6 h-6 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5" />
                            </svg></span>
                        <span className='text-sm'>Confirmer</span>
                    </button>
                </div>
                <div className='flex items-center flex-col space-y-7 justify-center mt-8'>
                    <CustomFooter styelefooter='text-justify mx-9 text-[14.5px]' />

                </div>

            </div>

        </div>
        </section >

    )
}

export default UpdatePassword2